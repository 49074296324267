import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeaturedProjects = () => {
  const data = useStaticQuery(graphql`
    query FeaturedProjectsQuery {
      allMarkdownRemark(
        limit: 3
        sort: { order: ASC, fields: frontmatter___order }
        filter: { frontmatter: { category: { eq: "project" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              tag
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `)
  return (
    <div className="section projects">
      <div className="content">
        <h2>Proiecte recente</h2>
        <div className="columns is-variable is-4-widescreen">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id} className="column card" data-sal="slide-up">
              <a rel="nofollow" href={node.frontmatter.link}>
                <div className="card-image">
                  <Img
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                    alt={node.frontmatter.title}
                  />
                </div>
                <div className="card-content">
                  {" "}
                  <h3>
                    {node.frontmatter.title}
                    <span></span>
                  </h3>
                  <p>{node.frontmatter.description}</p>
                  {/* <div className="tags">{node.frontmatter.tag}</div> */}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturedProjects
