import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___order }
        filter: { frontmatter: { category: { eq: "service" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              tag
              image {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `)
  return (
    <div className="section services">
      <div className="container">
        <div className="content">
          <h2>Ce îți oferim?</h2>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div
              key={node.id}
              className="columns is-variable is-8-widescreen"
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
            >
              <div className="column">
                <Img
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                  alt={node.frontmatter.title}
                />
              </div>
              <div className="column">
                <h3>{node.frontmatter.title}</h3>
                <p>{node.frontmatter.description}</p>
                <div className="tags">{node.frontmatter.tag}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Services
