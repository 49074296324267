import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"
import LinkButton from "../components/elements/button"
import Reviews from "../components/Reviews"
import Services from "../components/services"
import FeaturedProjects from "../components/featuredProjects"

const IndexPage = () => (
  <Layout>
    <SEO title="Sixpixels" />
    <div className="section">
      <div className="container">
        <Title title="Hub digital cu soluții complete de business" />
        <SubTitle subtitle="Strategie, design și dezvoltare de aplicații web. Construim produse digitale la care oamenii să tot revină." />
        <LinkButton url="/contact" text="Contactează-ne" />
      </div>
    </div>
    <FeaturedProjects />
    <Reviews />
    <Services />
  </Layout>
)

export default IndexPage
